import styles from "./Vocacionamiento.module.css";
import { Breadcrumbs, Button, Form, Popup, Sidebar } from "../../components";
import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import Cookies from "js-cookie";

const IMAGE_URL = "https://pivoteaprodblobstorage.blob.core.windows.net/questions";

export default function Vocacionamiento() {
  const navigate = useNavigate();
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [sidebar, setSidebar] = useState([]);
  const [moduleTitle, setModuleTitle] = useState("");
  const [content, setContent] = useState([]);
  const [hidePrev, setHidePrev] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  const moduleToDisplay = useRef("");
  const stepToDisplay = useRef("");
  const formAnswers = useRef([]);
  const initialAnswers = useRef([]);
  const hasSurvey = useRef(false);
  const [validateNext, setValidateNext] = useState(false);
  const surveyLength = useRef(0);
  const [blockTitle, setBlockTitle] = useState("");
  const [loader, setLoader] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  const displayContent = () => {
    let final = false;
    let hasAns = false;

    axios
      .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
      .then((response) => {
        const allSteps = response.data.data.steps;
        const currentStepIndex = allSteps.findIndex(
          (element) => element.id_step === stepToDisplay.current
        );
        setBlockTitle(allSteps[currentStepIndex].title);

        // Get Module Title
        axios
          .get(url + "/modules/get-modules")
          .then((response) => {
            const modules = response.data.data.modules;
            const currentIndex = modules.findIndex(
              (element) => element.id_module === moduleToDisplay.current
            );

            setModuleTitle(modules[currentIndex].module_title);

            if (currentIndex === 0 && currentStepIndex === 0) {
              setHidePrev(true);
              setHideNext(false);
            } else if (
              currentIndex === modules.length - 1 &&
              currentStepIndex === allSteps.length - 1
            ) {
              final = true;
              setHidePrev(false);
              setHideNext(true);
            } else {
              setHideNext(false);
              setHidePrev(false);
            }

            const steps = [];
            for (let i = 0; i < allSteps.length; i++) {
              currentStepIndex >= i
                ? steps.push({
                    id: allSteps[i].id_step,
                    title: allSteps[i].title,
                    status: "complete",
                  })
                : steps.push({
                    id: allSteps[i].id_step,
                    title: allSteps[i].title,
                    status: "pending",
                  });
            }
            setSidebar([...steps]);
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(url + `/modules/get-blocks/${stepToDisplay.current}`)
          .then(async (response) => {
            const blocks = [];
            const titleIndex = sidebar.findIndex(
              (element) => element.id === stepToDisplay.current
            );

            for (let i = 0; i < response.data.data.blocks.length; i++) {
              if (response.data.data.blocks[i].title.length > 0) {
                blocks.push(<h2>{response.data.data.blocks[i].title}</h2>);
              }
              if (response.data.data.blocks[i].block_description != null) {
                blocks.push(
                  <div
                    dangerouslySetInnerHTML={{
                      __html: response.data.data.blocks[i].block_description,
                    }}
                  />
                );
              }
              if (response.data.data.blocks[i].image.length > 0) {
                blocks.push(
                  <img
                    src={`${IMAGE_URL}/${response.data.data.blocks[i].image}`}
                  />
                );
              }
              if (
                response.data.data.blocks[i].survey != null &&
                response.data.data.blocks[i].survey.hasOwnProperty("survey")
              ) {
                const newSurvey = response.data.data.blocks[
                  i
                ].survey.survey.filter((el) => el != null);
                surveyLength.current += newSurvey.length;

                await axios
                  .get(
                    `${url}/answers/get-answers/${authUser.id}/${response.data.data.blocks[i].id_block}`
                  )
                  .then((responseAns) => {
                    if (responseAns.data.data.answers.length > 0) {
                      hasAns = true;
                      hasSurvey.current = true;
                      const answers = responseAns.data.data.answers;
                      for (let j = 0; j < answers.length; j++) {
                        const ansIndex = newSurvey.findIndex(
                          (element) =>
                            element.id_question === answers[j].id_question
                        );
                        newSurvey[ansIndex].answer = answers[j].answer;
                      }
                      blocks.push(
                        <Form
                          survey={newSurvey}
                          block={response.data.data.blocks[i].id_block}
                          user={authUser?.id}
                          callback={formCallback}
                        />
                      );
                    } else {
                      hasAns = false;
                      hasSurvey.current = true;
                      blocks.push(
                        <Form
                          survey={newSurvey}
                          block={response.data.data.blocks[i].id_block}
                          user={authUser?.id}
                          callback={formCallback}
                        />
                      );
                    }
                  })
                  .catch((error) => console.error(error));
              }
            }

            setContent(Array.from(blocks));

            if (final && hasAns) {
              setValidateNext(false);
              setHideNext(true);
            } else {
              setValidateNext(hasSurvey.current);
              setHideNext(false);
            }
            setLoader(false);
          })
          .catch((error) => {
            console.error(error);
            setContent(
              Array.from([<p>No se encontró contenido para este paso.</p>])
            );
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const quickRender = (isPast) => {
    // Use this function when the sidebar is already set
    // Get Blocks
    let final = false;
    let hasAns = false;
    axios
      .get(url + `/modules/get-blocks/${stepToDisplay.current}`)
      .then(async (response) => {
        const blocks = [];
        for (let i = 0; i < response.data.data.blocks.length; i++) {
          if (response.data.data.blocks[i].title.length > 0) {
            blocks.push(<h2>{response.data.data.blocks[i].title}</h2>);
          }
          if (response.data.data.blocks[i].block_description != null) {
            blocks.push(
              <div
                dangerouslySetInnerHTML={{
                  __html: response.data.data.blocks[i].block_description,
                }}
              />
            );
          }
          if (response.data.data.blocks[i].image.length > 0) {
            blocks.push(
              <img src={`${IMAGE_URL}/${response.data.data.blocks[i].image}`} />
            );
          }
          if (
            response.data.data.blocks[i].survey != null &&
            response.data.data.blocks[i].survey.hasOwnProperty("survey")
          ) {
            
            const newSurvey = response.data.data.blocks[i].survey.survey.filter(
              (el) => el != null
            );
            surveyLength.current += newSurvey.length;
            // Check if answers already exist
            await axios
              .get(
                `${url}/answers/get-answers/${authUser.id}/${response.data.data.blocks[i].id_block}`
              )
              .then((responseAns) => {
                if (responseAns.data.data.answers.length > 0) {
                  hasAns = true;
                  // Build survey object with answers
                  const answers = responseAns.data.data.answers;
                  initialAnswers.current = [...initialAnswers.current, ...answers];
                  for (let j = 0; j < answers.length; j++) {
                    // Find index of current answer
                    const ansIndex = newSurvey.findIndex(
                      (element) => element.id_question == answers[j].id_question
                    );
                    newSurvey[ansIndex].answer = answers[j].answer;
                  }
                  blocks.push(
                    <Form
                      survey={newSurvey}
                      block={response.data.data.blocks[i].id_block}
                      user={authUser.id}
                      callback={formCallback}
                    />
                  );
                } else {
                  hasAns = false;
                  hasSurvey.current = true;
                  blocks.push(
                    <Form
                      survey={newSurvey}
                      block={response.data.data.blocks[i].id_block}
                      user={authUser.id}
                      callback={formCallback}
                    />
                  );
                }
              })
              .catch((error) => console.error(error));
          }
        }
        setContent(Array.from(blocks));
        setValidateNext(isPast ? false : hasSurvey.current);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setContent(
          Array.from([<p>No se encontró contenido para este paso.</p>])
        );
      });
  };

  const formCallback = (answers) => {
    for (let i = 0; i < answers.length; i++) {
      const index = formAnswers.current.findIndex(
        (element) => element.id_question === answers[i].id_question
      );
      if (index > -1) {
        formAnswers.current[index] = answers[i];
      } else {
        formAnswers.current.push(answers[i]);
      }
    }

    if (formAnswers.current.length === surveyLength.current) {
      setValidateNext(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    
    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];

    const storedUser = Cookies.get("authUser");
    if (!isLoggedIn && storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.token) {
        setAuthUser(user);
        setIsLoggedIn(true);
        onFirstLoad();
      } else {
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/login");
      }
    } else if (!isLoggedIn) {
      navigate("/login");
    } else {
      onFirstLoad();
    }
  }, [authUser, isLoggedIn]);

  const onFirstLoad = () => {
    if (authUser?.current_module != null && isFirstLoad) {
      setIsFirstLoad(false);
      moduleToDisplay.current = authUser.current_module;
      stepToDisplay.current = authUser.current_step;
      displayContent();
    } else if (authUser && isFirstLoad) {
      setIsFirstLoad(false);
      axios
        .get(`${url}/modules/get-modules`)
        .then((response) => {
          const currentModule = response.data.data.modules[0].id_module;
          axios
            .get(`${url}/modules/get-steps/${currentModule}`)
            .then((response) => {
              const currentStep = response.data.data.steps[0].id_step;

              authUser.current_module = currentModule;
              authUser.current_step = currentStep;
              Cookies.set("authUser", JSON.stringify(authUser), {
                expires: 7,
              });

              moduleToDisplay.current = authUser?.current_module;
              stepToDisplay.current = authUser?.current_step;
              displayContent();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onNext = () => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // if formanswers answers are different than the initial answers, update hasSurvey.current
    var hasBeenAnswered = false;
    
    for (let i = 0; i < formAnswers.current.length; i++) {
      
      const index = initialAnswers.current.findIndex(
        (element) =>
          element.id_question == formAnswers.current[i].id_question &&
          element.answer != formAnswers.current[i].answer
      );
      if (index > -1) {
        hasSurvey.current = true;
        hasBeenAnswered = true;
        break;
      }
    }
    if (
      (hasSurvey.current &&
      formAnswers.current.length == surveyLength.current) ||  hasBeenAnswered
    ) {
      axios
        .post(url + "/answers/add-answer", { answers: formAnswers.current, source: "life-design" })
        .then((response) => {
        })
        .catch((error) => console.error(error));
    }
    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];
    // Reset
    // Check if current_step needs to be updated
    // Get index of current_step
    const currentIndex = sidebar.findIndex(
      (element) => element.id == authUser.current_step
    );
    // Get index of displayed step
    const currentStep = sidebar.findIndex(
      (element) => element.id == stepToDisplay.current
    );
    // Compare with the step that's being displayed?
    if (currentStep > -1 && currentStep < currentIndex) {
      // The next step is in the same module and has been previously completed
      // No need to update user progress
      stepToDisplay.current = sidebar[currentStep + 1].id;
      setBlockTitle(sidebar[currentStep + 1].title);
      //Get modules to see if it's last index
      axios.get(url + "/modules/get-modules").then((response) => {
        const modules = response.data.data.modules;
        // Find index of current module
        const moduleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        // Check if prev or next button should be disabled
        if (moduleIndex === 0 && currentStep === 0) {
          //It's the first module and first step. There's no prev
          setHidePrev(true);
          setHideNext(false);
        } else if (
          currentIndex + 1 === modules.length - 1 &&
          currentStep + 2 === sidebar.length - 1
        ) {
          //It's the last step of the last module
          setHidePrev(false);
          setHideNext(true);
        } else {
          //It's somewhere in the middle
          setHideNext(false);
          setHidePrev(false);
        }
        quickRender();
      });
    } else if (currentIndex === -1) {
      // Current Step is in another module, no need to update progress
      // Get modules
      axios.get(url + "/modules/get-modules").then((response) => {
        // Find index
        const modules = response.data.data.modules;
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        // Set module to display as next module
        moduleToDisplay.current = modules[currentModuleIndex + 1].id_module;
        setModuleTitle(modules[currentModuleIndex + 1].module_title);
        // Get Steps
        axios
          .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
          .then((response) => {
            stepToDisplay.current = response.data.data.steps[0].id_step;
            // Get index of currently displayed step
            const currentStep = 0;
            setBlockTitle(response.data.data.steps[currentStep].title);
            // Check if prev or next button should be disabled
            if (currentModuleIndex === 0) {
              //It's the first module and first step. There's no prev
              setHidePrev(true);
              setHideNext(false);
            } else if (
              currentModuleIndex === modules.length - 1 &&
              currentStep === response.data.data.steps.length - 1
            ) {
              //It's the last step of the last module
              setHidePrev(false);
              setHideNext(true);
            } else {
              //It's somewhere in the middle
              setHideNext(false);
              setHidePrev(false);
            }
            // Set sidebar
            // Check if the new module that's being displayed matches user's progress
            if (authUser.current_module == moduleToDisplay.current) {
              // Set sidebar contemplating user progress
              // Get index of user's current_step
              const currentStepIndex = response.data.data.steps.findIndex(
                (element) => element.id_step == authUser.current_step
              );
              //Create array for sidebar
              const steps = [];
              for (let i = 0; i < response.data.data.steps.length; i++) {
                i <= currentStepIndex
                  ? steps.push({
                      id: response.data.data.steps[i].id_step,
                      title: response.data.data.steps[i].title,
                      status: "complete",
                    })
                  : steps.push({
                      id: response.data.data.steps[i].id_step,
                      title: response.data.data.steps[i].title,
                      status: "pending",
                    });
              }
              setSidebar([...steps]);
              quickRender();
            } else {
              // Set sidebar where the user has already completed all steps
              //Create array for sidebar
              const steps = [];
              for (let i = 0; i < response.data.data.steps.length; i++) {
                steps.push({
                  id: response.data.data.steps[i].id_step,
                  title: response.data.data.steps[i].title,
                  status: "complete",
                });
              }
              setSidebar([...steps]);
              quickRender();
            }
          })
          .catch((error) => console.error(error));
      });
    } else if (currentIndex + 1 < sidebar.length) {
      // Progress needs to be updated, there's a next step in the current module
      axios
        .put(url + `/user/update-user/${authUser.id}`, {
          current_module: authUser.current_module,
          current_step: sidebar[currentIndex + 1].id,
        })
        .then((response) => {
          const newAuthUser = { ...authUser };
          newAuthUser.current_step = sidebar[currentIndex + 1].id;
          setAuthUser({ ...newAuthUser });
          // window.localStorage.setItem(
          //   "PIVOTEA_CURRENT_STEP",
          //   JSON.stringify(sidebar[currentIndex + 1].id)
          // );

          setAuthUser({ ...newAuthUser });

          const stepIndex = currentIndex + 1;
          stepToDisplay.current = sidebar[currentIndex + 1].id;
          setBlockTitle(sidebar[currentIndex + 1].title);
          //Update sidebar content
          const newSidebar = Array.from(sidebar);
          newSidebar[currentIndex + 1].status = "complete";
          setSidebar(Array.from(newSidebar));
          // Get modules to get current index
          axios
            .get(url + "/modules/get-modules")
            .then((response) => {
              const modules = response.data.data.modules;
              const currentModuleIndex = modules.findIndex(
                (element) => element.id_module == authUser.current_module
              );
              if (
                currentModuleIndex + 1 === modules.length &&
                stepIndex + 1 === newSidebar.length
              ) {
                //It's the last step of the last module
                setHidePrev(false);
                setHideNext(true);
              } else {
                //It's somewhere in the middle
                setHideNext(false);
                setHidePrev(false);
              }
            })
            .catch((error) => console.error(error));
          quickRender();
        })
        .catch((error) => console.error(error));
    } else if (currentIndex + 1 == sidebar.length) {
      // Progress needs to be updated, move on to the next module
      // 1. Get Modules
      axios
        .get(url + "/modules/get-modules")
        .then((response) => {
          // 2. Find index
          const modules = response.data.data.modules;
          const currentModuleIndex = modules.findIndex(
            (element) => element.id_module == authUser.current_module
          );
          // Check if it's the last module
          if (+currentModuleIndex + 1 >= modules.length) {
            // It's the last module
            // Update user data
            authUser.current_step = sidebar[currentIndex + 1].id;
            // window.localStorage.setItem(
            //   "PIVOTEA_CURRENT_STEP",
            //   JSON.stringify(sidebar[currentIndex + 1].id)
            // );

            setAuthUser({ ...authUser });

            stepToDisplay.current = sidebar[currentIndex + 1].id;

            axios
              .put(`${url}/user/update-user/${authUser.id}`, {
                current_module: authUser.current_module,
                current_step: authUser.current_step,
              })
              .then((response) => {
                setHidePrev(false);
                setHideNext(true);
                quickRender();
                return;
              });
          } else {
            // Set Module to next index
            authUser.current_module =
              modules[currentModuleIndex + 1].id_module;
            // window.localStorage.setItem(
            //   "PIVOTEA_CURRENT_MODULE",
            //   JSON.stringify(modules[currentModuleIndex + 1].id_module)
            // );

            setAuthUser({ ...authUser });
            
            // Set module title for sidebar
            setModuleTitle(modules[currentModuleIndex + 1].module_title);
          }
          // 3. Get Steps for new module
          axios
            .get(url + `/modules/get-steps/${authUser.current_module}`)
            .then((response) => {
              // Set Step to first index
              authUser.current_step =
                response.data.data.steps[0].id_step;
              // window.localStorage.setItem(
              //   "PIVOTEA_CURRENT_STEP",
              //   JSON.stringify(response.data.data.steps[0].id_step)
              // );

              setAuthUser({ ...authUser });

              stepToDisplay.current = response.data.data.steps[0].id_step;
              setBlockTitle(response.data.data.steps[0].title);
              // Check if prev or next button should be disabled
              if (currentModuleIndex === 0) {
                //It's the first module and first step. There's no prev
                setHidePrev(true);
                setHideNext(false);
              } else if (
                currentModuleIndex === modules.length - 1 &&
                sidebar.length === 1
              ) {
                //It's the last step of the last module
                setHidePrev(false);
                setHideNext(true);
              } else {
                //It's somewhere in the middle
                setHideNext(false);
                setHidePrev(false);
              }
              //Create array for sidebar
              const steps = [];

              for (let i = 0; i < response.data.data.steps.length; i++) {
                i === 0
                  ? steps.push({
                      id: response.data.data.steps[i].id_step,
                      title: response.data.data.steps[i].title,
                      status: "complete",
                    })
                  : steps.push({
                      id: response.data.data.steps[i].id_step,
                      title: response.data.data.steps[i].title,
                      status: "pending",
                    });
              }
              setSidebar([...steps]);
              // 4. Update User
              axios
                .put(url + `/user/update-user/${authUser.id}`, {
                  current_module: authUser.current_module,
                  current_step: authUser.current_step,
                })
                .then((response) => {
                  quickRender();
                });
            });
        })
        .catch((error) => console.error(error));
    } else {
      // Progress doesn't need to be updated, there's a next step in the current module
      stepToDisplay.current = sidebar[currentIndex + 1].id;
      setBlockTitle(sidebar[currentIndex + 1].title);
      // Get modules to find current index
      axios
        .get(url + "/modules/get-modules")
        .then((response) => {
          const modules = response.data.data.modules;
          const currentModuleIndex = modules.findIndex(
            (element) => element.id_module === moduleToDisplay.current
          );
          const currentStepIndex = currentIndex + 1;
          // Check if prev or next button should be disabled
          if (currentModuleIndex === 0 && currentStepIndex === 0) {
            //It's the first module and first step. There's no prev
            setHidePrev(true);
            setHideNext(false);
          } else if (
            currentModuleIndex === modules.length - 1 &&
            currentStepIndex === sidebar.length - 1
          ) {
            //It's the last step of the last module
            setHidePrev(false);
            setHideNext(true);
          } else {
            //It's somewhere in the middle
            setHideNext(false);
            setHidePrev(false);
          }
        })
        .catch((error) => console.error(error));
      quickRender();
    }
  };

  const onPrev = () => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];
    // Get index of current step
    const currentStepIndex = sidebar.findIndex(
      (element) => element.id == stepToDisplay.current
    );
    if (currentStepIndex > 0) {
      // Previous step is in the same module
      stepToDisplay.current = sidebar[currentStepIndex - 1].id;
      setBlockTitle(sidebar[currentStepIndex - 1].title);
      // Get modules to get index
      axios.get(url + "/modules/get-modules").then((response) => {
        const modules = response.data.data.modules;
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );

        // Check if prev or next button should be disabled
        if (currentModuleIndex === 0 && currentStepIndex - 1 === 0) {
          //It's the first module and first step. There's no prev
          setHidePrev(true);
          setHideNext(false);
        } else if (
          currentModuleIndex === modules.length - 1 &&
          currentStepIndex - 1 === sidebar.length - 1
        ) {
          //It's the last step of the last module
          setHidePrev(false);
          setHideNext(true);
        } else {
          //It's somewhere in the middle
          setHideNext(false);
          setHidePrev(false);
        }
      });
      quickRender();
    } else {
      // Previous step is in the previous module
      // Get modules
      axios
        .get(url + "/modules/get-modules")
        .then((response) => {
          const modules = response.data.data.modules;
          // Get index of current module
          const currentModuleIndex = modules.findIndex(
            (element) => element.id_module == moduleToDisplay.current
          );
          moduleToDisplay.current = modules[currentModuleIndex - 1].id_module;
          // Set module title
          setModuleTitle(modules[currentModuleIndex - 1].module_title);
          // Get steps of previous module
          axios
            .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
            .then((response) => {
              // Set step
              const currentStepIndex = response.data.data.steps.length - 1;
              stepToDisplay.current =
                response.data.data.steps[currentStepIndex].id_step;
              setBlockTitle(response.data.data.steps[currentStepIndex].title);
              // Sidebar content
              const steps = [];
              for (let i = 0; i < response.data.data.steps.length; i++) {
                steps.push({
                  id: response.data.data.steps[i].id_step,
                  title: response.data.data.steps[i].title,
                  status: "complete",
                });
              }
              setSidebar([...steps]);

              // Check if prev or next button should be disabled
              if (currentModuleIndex - 1 === 0 && currentStepIndex === 0) {
                //It's the first module and first step. There's no prev
                setHidePrev(true);
                setHideNext(false);
              } else if (
                currentModuleIndex - 1 === modules.length - 1 &&
                currentStepIndex === steps.length - 1
              ) {
                //It's the last step of the last module
                setHidePrev(false);
                setHideNext(true);
              } else {
                //It's somewhere in the middle
                setHideNext(false);
                setHidePrev(false);
              }
              quickRender();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const goToStep = (step) => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];

      // Previous step is in the same module
      stepToDisplay.current = step.id;
      setBlockTitle(step.title);
      // Get modules to get index
      axios.get(url + "/modules/get-modules").then((response) => {
        const modules = response.data.data.modules;
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        // Get index of current step
        const currentStepIndex = sidebar.findIndex(
          (element) => element.id == stepToDisplay.current
        );
        // Check if prev or next button should be disabled
        if (currentModuleIndex === 0 && currentStepIndex - 1 === 0) {
          //It's the first module and first step. There's no prev
          setHidePrev(true);
          setHideNext(false);
        } else if (
          currentModuleIndex === modules.length - 1 &&
          currentStepIndex - 1 === sidebar.length - 1
        ) {
          //It's the last step of the last module
          setHidePrev(false);
          setHideNext(true);
        } else {
          //It's somewhere in the middle
          setHideNext(false);
          setHidePrev(false);
        }
      });
      quickRender();
  };

  const nextModule = () => {
    // Get modules
    axios
      .get(url + "/modules/get-modules")
      .then((response) => {
        const modules = response.data.data.modules;
        // Get index of current module
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        moduleToDisplay.current = modules[currentModuleIndex + 1].id_module;
        // Set module title
        setModuleTitle(modules[currentModuleIndex + 1].module_title);
        // Get steps of next module
        axios
          .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
          .then((response) => {
            // Set step
            const currentStepIndex = authUser.current_module == moduleToDisplay.current ? response.data.data.steps.findIndex(element => element.id_step == authUser.current_step) : response.data.data.steps.length - 1;
            stepToDisplay.current =
              response.data.data.steps[currentStepIndex].id_step;
            setBlockTitle(response.data.data.steps[currentStepIndex].title);
            // Sidebar content
            const steps = [];
            for (let i = 0; i < response.data.data.steps.length; i++) {
              steps.push({
                id: response.data.data.steps[i].id_step,
                title: response.data.data.steps[i].title,
                status: i <= currentStepIndex ? "complete" : "pending",
              });
            }
            setSidebar([...steps]);
            // Check if prev or next button should be disabled
            if (currentModuleIndex + 1 === modules.length && currentStepIndex === 0) {
              //It's the first module and first step. There's no prev
              setHidePrev(true);
              setHideNext(false);
            } else if (
              currentModuleIndex + 1 === modules.length - 1 &&
              currentStepIndex === steps.length - 1
            ) {
              //It's the last step of the last module
              setHidePrev(false);
              setHideNext(true);
            } else {
              //It's somewhere in the middle
              setHideNext(false);
              setHidePrev(false);
            }
            quickRender();
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const prevModule = () => {
    // Get modules
    axios
      .get(url + "/modules/get-modules")
      .then((response) => {
        const modules = response.data.data.modules;
        // Get index of current module
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        moduleToDisplay.current = modules[currentModuleIndex - 1].id_module;
        // Set module title
        setModuleTitle(modules[currentModuleIndex - 1].module_title);
        // Get steps of next module
        axios
          .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
          .then((response) => {
            // Set step
            const currentStepIndex = response.data.data.steps.length - 1;
            stepToDisplay.current =
              response.data.data.steps[currentStepIndex].id_step;
            setBlockTitle(response.data.data.steps[currentStepIndex].title);
            // Sidebar content
            const steps = [];
            for (let i = 0; i < response.data.data.steps.length; i++) {
              steps.push({
                id: response.data.data.steps[i].id_step,
                title: response.data.data.steps[i].title,
                status: "complete",
              });
            }
            setSidebar([...steps]);
            // Check if prev or next button should be disabled
            if (currentModuleIndex - 1 === 0 && currentStepIndex === 0) {
              //It's the first module and first step. There's no prev
              setHidePrev(true);
              setHideNext(false);
            } else if (
              currentModuleIndex - 1 === modules.length - 1 &&
              currentStepIndex === steps.length - 1
            ) {
              //It's the last step of the last module
              setHidePrev(false);
              setHideNext(true);
            } else {
              //It's somewhere in the middle
              setHideNext(false);
              setHidePrev(false);
            }
            var isPast = false;
            if (stepToDisplay.current == steps[steps.length - 1].id) {
              if (steps.find((element) => element.id == stepToDisplay.current)?.status == "complete") {
                isPast = true;
              }
            }
            quickRender(isPast);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className={styles.vocaLayout}>
      {loader === true && (
        <div className={styles.loader}>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="var(--primary)"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {loader === false && (
        <>
          <div className={styles.col25}>
            <Sidebar
              title={moduleTitle}
              steps={sidebar}
              goToStep={goToStep}
              currentStep={stepToDisplay}
              nextModule={nextModule}
              prevModule={prevModule}
            />
          </div>
          <div className={styles.col75}>
            <Breadcrumbs
              prev={[{ prev: "Vocacional", link: "/" }]}
              current={moduleTitle}
            />
            {blockTitle && <h1>{blockTitle}</h1>}
            {content}
            <div className={styles.btnGroup}>
              {!hidePrev && (
                <Button
                  text="Anterior"
                  type="filled"
                  icon="back"
                  corners="rounded"
                  onClick={onPrev}
                />
              )}

              {!hideNext && validateNext && (
                <Button
                  text="Siguiente"
                  type="disabled"
                  icon="next"
                  corners="rounded"
                />
              )}

              {!hideNext && !validateNext && (
                <Button
                  text="Siguiente"
                  type="filled"
                  icon="next"
                  corners="rounded"
                  onClick={onNext}
                  disabled={validateNext}
                />
              )}

              {hideNext && validateNext && (
                <Button
                  text="Finalizar"
                  type="disabled"
                  corners="rounded"
                  disabled={validateNext}
                />
              )}
              {hideNext && !validateNext && (
                <Popup
                  answers={formAnswers.current}
                  user={authUser.id}
                  step={sidebar[sidebar.length - 1]?.id}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
