import React from 'react';
import styles from './Terms.module.css';
import { useNavigate } from "react-router-dom";
import termsData from './termsData';
import logo from "../../assets/pivotea-logo.png";


const TermsAndConditions = () => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };



    return (
        <div className={styles.termsLayout}>
            <img src={logo} alt="Pivotea" />
            <h1 className={styles.pageTitle}>Términos y Condiciones</h1>
            <div className={styles.termsContent}>
                {termsData.terms.map((section, sectionIndex) => (
                    <div key={sectionIndex} className={styles.section}>
                        <h2>{section.sectionTitle}</h2>
                        <p>{section.content}</p>

                        {section.subsections &&
                            section.subsections.map((subsection, subsectionIndex) => (
                                <div key={subsectionIndex} className={styles.subsection}>
                                    <h3>{subsection.subsectionTitle}</h3>
                                    <p>{subsection.content}</p>

                                    {subsection.contentPoint && subsection.contentPoint.points && (
                                        <ul className={styles.pointsList}>
                                            {subsection.contentPoint.points.map((point, pointIndex) => (
                                                <li key={pointIndex} className={styles.pointItem}>
                                                    <strong>{point.title}:</strong> {point.contentpoint}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    {/* Agregar la sección de nota si existe */}
                                    {subsection.note && (
                                        <div className={styles.contentSec}>
                                            <p className={styles.noteTitle}>Nota:</p>
                                            <p>{subsection.note}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                ))}
            </div>

            <div className={styles.buttonContainer}>
                <button className={styles.backButton} onClick={goBack}>
                    Regresar
                </button>
            </div>
        </div>
    );
};

export default TermsAndConditions;
